.lineContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.body {
    flex: 1;
    height: 3px;
    border-radius: 5px;
    max-width: 80%;
}

.long {
    background-color: var(--longColor);
}

.short {
    background-color: var(--shortColor);
}

.green {
    color: var(--longColor);
}

.red {
    color: var(--shortColor);
}

.white {
    color: #fff;
}

.gold {
    color: var(--mainColor);
}


.priceBody {
    background-color: var(--priceColor);
}

.price {
    width: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 5px;
}

.profit {
    width: auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 5px;
}

.pendingOrder {
    opacity: 0.5;
}