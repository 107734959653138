
.mainContainer {
    width: 100vw;
    height: 100vh;
    padding: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.loginContainer {
    background-color: #fff;
    border-radius: 2rem;
    width: 100%;
    max-width: 600px;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    box-shadow: 0px 0px 10px #ddd;
}