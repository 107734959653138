.container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 1rem;
}
.levelContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 10px;
    border: 1px solid var(--mainColor);
    flex: 1;
    border-radius: 25px;
}
.priceContainer {
    border: 1px solid var(--mainColor);
    background-color: var(--mainColor);
    display: flex;
    justify-content: flex-end;
}

.price {
    width: 20%;
    display: flex;
    justify-content: flex-end;
}