
:root {
  --mainColor: #1a9fff;
  --backgroundColor: #fff;
  --longColor: #008000;
  --shortColor: #FF0000;
  --priceColor: #fff;
}

* {
  padding: 0;
  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  padding: 0;
  margin: 0;
  color: var(--mainColor);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  max-width: 100vw;
}
input {
  margin-bottom: 10px !important;
  color: #111 !important;
}

button, button:active, button:focus, button:hover, .ant-btn, .ant-btn:hover, .ant-btn:active, .ant-btn:focus, .ant-dropdown, .ant-dropdown-menu-item, .ant-dropdown-menu {
}

.ant-dropdown-arrow::before {
  background-color: var(--mainColor) !important;
}

.App {
  display: flex;
  flex-direction: column;
  background-color: var(--backgroundColor);
  width: 100%;
  max-width: 100%;
  height: auto;
  min-height: 100vh;
}

.header {
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  box-shadow: 0px 1px 10px #ddd;
}

.profit {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  font-size: 2rem;
  font-weight: 700;
  border-radius: 1rem;
}

table {
  border: 1px solid;
  width: 100%;
}

table, tr, td {
  border-color: var(--mainColor);
}

td, th {
  text-align: left;
  padding: 5px;
}

tr:nth-child(even) {
  background-color: #f5f5f5;
}

