.gridContainer {
    width: 100%;
    height: auto;
    padding: 10px;
    overflow: hidden;
}
.grid {
    width: 100%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    height: auto;
    padding: 10px;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0px 0px 10px #ddd;
    position: relative;
}
.symbolData {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.headerText {
    font-size: 33px;
    font-weight: 700;
    margin: 0;
}

.levels {
    width: 100%;
}

.header {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}

.smallText {
    font-size: 12px;
}

.flexStart {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.flexBetween {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.tag {
    padding: 5px;
    border: 1px solid var(--mainColor);
    border-radius: 2rem;
    color: var(--mainColor);
    margin: 0.7rem 0.5rem 0.7rem 0rem;
    display: flex;
    font-size: 11px;
    justify-content: center;
    align-items: center;
}

.mainHeader {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: #fff;
    padding: 1rem;
}

.flex {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.headerProfit {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
    color: #fff;
    padding: 0.5rem;
    font-size: 1.5rem;
    border-bottom-left-radius: 5px;
}

@media only screen and (min-width: 1200px) {
    .gridContainer {
        width: 25%;
    }
}

@media only screen and (max-width: 1200px) {
    .gridContainer {
        width: 50%;
    }
}

@media only screen and (max-width: 600px) {
    .gridContainer {
        width: 100%;
    }
}